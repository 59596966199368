import { ref } from "vue";
import { projectAuth, projectFirestore } from "../firebase/config";
import userCollection from "../composables/userCollection";
import useCookies from "./useCookies";

function split(array, n) {
	let [...arr] = array;
	var res = [];
	while (arr.length) {
		res.push(arr.splice(0, n));
	}
	return res;
}

const checklistCollection = () => {
	const error = ref(null);
	const checklist = ref(null);
	const checklists = ref(null);
	const { getUser, getUserForThirdParty, users, thirdpertyusers, e } =
		userCollection();
	const { getCookie } = useCookies();

	const syncChecklistSearchTags = async (id) => {
		const companyId = id;

		const checklistsRef = projectFirestore
			.collection("Checklist")
			.where("companyId", "==", companyId)
			.where("isArchived", "==", false);

		const checklists = await checklistsRef.get();

		let currentChecklistTags = [];

		checklists.docs.map((doc) => {
			doc.data().tags.forEach((tag) => {
				if (!currentChecklistTags.includes(tag)) {
					currentChecklistTags.push(tag);
				}
			});
		});

		const templateTagsRef = projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("templatetags");

		const tTags = await templateTagsRef.get();

		const collectionTags = new Set(tTags.docs.map((doc) => doc.data().tag));
		const deleteTags = Array.from(collectionTags).filter(
			(tag) => !currentChecklistTags.includes(tag)
		);

		let tagPromises = [];

		for (const tag of deleteTags) {
			const doc = tTags.docs.find((doc) => doc.data().tag === tag);
			tagPromises.push(doc.ref.delete());
		}

		const addTags = currentChecklistTags.filter(
			(tag) => !collectionTags.has(tag)
		);

		for (const tag of addTags) {
			tagPromises.push(templateTagsRef.add({ tag: tag }));
		}

		await Promise.all(tagPromises);
	};

	const getChecklist = async (id) => {
		// Create a reference to the checklist
		const checklistRef = projectFirestore.collection("Checklist").doc(id);

		// Create a reference to the template sub-collection
		const templateRef = checklistRef.collection("Template");

		// Create a reference to the checklist's actions
		const actionsRef = projectFirestore
			.collection("Action")
			.where("checklistId", "==", id);

		// Get everything we can with the checklist id
		const [checklistDoc, templateDocs, actionDocs] = await Promise.all([
			checklistRef.get(),
			templateRef.get(),
			actionsRef.get(),
		]).catch((err) => {
			error.value = err.message;
		});

		// Build the checklist object
		const checklist = ref();
		checklist.value = { ...checklistDoc.data() };

		// Manage company/third party company IDs
		let companyId;

		if (checklist.value.thirdPartyCompanyId) {
			companyId = checklist.value.thirdPartyCompanyId;

			// To add userData to the checklist
			// This gets all of the active employees of the third party for the People Question
			await getUserForThirdParty(companyId);
		} else {
			companyId = checklist.value.companyId;
		}

		checklist.value.companyId = companyId;

		// To add userData to the checklist
		// This get all the active employees of the current company for the People Question
		await getUser();

		checklist.value.userData = checklist.value.thirdPartyCompanyId
			? thirdpertyusers.value
			: users.value;

		// Fetch required data that's not included in the checklist doc
		const locationRef = projectFirestore
			.collection("companies")
			.doc(checklist.value.companyId)
			.collection("locations")
			.doc(checklist.value.location.id);

		const companyRef = projectFirestore
			.collection("companies")
			.doc(checklist.value.companyId);

		const authorRef = projectFirestore
			.collection("users")
			.doc(checklist.value.owner);

		async function fetchCompanyAndAuthorDocs() {
			const [companyDoc, authorDoc] = await Promise.all([
				companyRef.get(),
				authorRef.get(),
			]);

			// Add company document to the checklist
			checklist.value.companyName = companyDoc.data().companyName;
			checklist.value.companyDoc = companyDoc.data();

			// Add author doc to the checklist
			checklist.value.ownerName = authorDoc.data().fullName;
			checklist.value.ownerEmail = authorDoc.data().email;
			checklist.value.authorDoc = authorDoc.data();
		}

		if (checklist.value.location.id) {
			const locationDoc = await locationRef.get();

			// Add the location
			checklist.value.location.address = locationDoc.data().address;
			checklist.value.location.state = locationDoc.data().state;
			checklist.value.location.country = locationDoc.data().country;
			checklist.value.location.zip = locationDoc.data().zip;

			await fetchCompanyAndAuthorDocs();
		} else {
			await fetchCompanyAndAuthorDocs();
		}

		// Add the template
		const template = ref();

		templateDocs.forEach((doc) => {
			template.value = { id: doc.id, ...doc.data() };
		});

		// Add the actions
		let checklistActions = [];

		actionDocs.forEach((doc) => {
			let action = { id: doc.id, ...doc.data() };
			checklistActions.push(action);
		});

		checklist.value.actions = checklistActions;

		// Add the pages and page contents
		const pagesRef = templateRef.doc(template.value.id).collection("Pages");

		let pageDocs = await pagesRef.orderBy("order").get();

		checklist.value.pages = await Promise.all(
			pageDocs.docs.map(async (getPage) => {
				let page = getPage.data();

				const sectionRef = pagesRef.doc(page.id).collection("Sections");

				let sectionDocs = await sectionRef.orderBy("order").get();

				let sections = await Promise.all(
					sectionDocs.docs.map(async (sectionDoc) => {
						let section = {
							id: sectionDoc.id,
							...sectionDoc.data(),
						};

						const questionDocs = await sectionRef
							.doc(section.id)
							.collection("Questions")
							.orderBy("order")
							.get();

						let questions = await Promise.all(
							questionDocs.docs.map(async (questionDoc) => {
								let question = {
									docId: questionDoc.id,
									...questionDoc.data(),
								};

								// Equipment
								var equipmentDetails = [];
								if (question.equipments) {
									const splittedEquipments = split(
										question.equipments,
										10
									);
									for (
										let i = 0;
										i < splittedEquipments.length;
										i++
									) {
										const eqipCollection = companyRef
											.collection("equipment")
											.where(
												"identifier",
												"in",
												splittedEquipments[i]
											);

										const equipments =
											await eqipCollection.get();

										equipments.docs.map((doc) => {
											equipmentDetails.push(doc.data());
										});
									}
								}
								question.equipmentDetails = equipmentDetails;

								// Actions
								var actionCompleted = [];
								var actionIncomplete = [];

								if (
									checklistActions &&
									checklistActions.length > 0
								) {
									for (var action of checklistActions) {
										if (
											action.questionId == question.docId
										) {
											var data = {
												id: action.id,
												...action,
											};

											if (data.status == "Complete") {
												actionCompleted.push(data);
											} else {
												actionIncomplete.push(data);
											}

											question.completedActions =
												actionCompleted;
											question.incompleteActions =
												actionIncomplete;
										}
									}
								}
								return question;
							})
						);
						return {
							id: section.id,
							title: section.title,
							order: section.order,
							repeat: section.repeat,
							questions: questions,
						};
					})
				);
				return {
					id: page.id,
					title: page.title,
					order: page.order,
					sections: sections,
				};
			})
		).catch((err) => {
			error.value = err.message;
		});

		return checklist.value;
	};

	const getChecklists = async (
		id,
		role,
		companyId,
		status,
		startDate,
		endDate,
		searchTags,
		allChecklists,
		archived,
		equipmentId,
		peopleId,
		authoredByYou
	) => {
		let user = projectAuth.currentUser;

		let order;
		let sort;
		if (status == "scheduled") {
			order = "scheduledDate";
			sort = "asc";
		} else if (status == "active") {
			order = "createdAt";
			sort = "desc";
		} else {
			order = "completedAt";
			sort = "desc";
		}

		// Add the filter variables to an array
		let filters = [
			{
				field: "companyId",
				operator: "==",
				value: companyId,
			},
			{
				field: "thirdPartyCompanyId",
				operator: "==",
				value: companyId,
			},
			{
				field: "tags",
				operator: "array-contains",
				value: equipmentId,
			},
			{
				field: "users",
				operator: "array-contains",
				value: peopleId,
			},
			{
				field: "status",
				operator: "==",
				value: status,
			},
			{
				field: "isArchived",
				operator: "==",
				value: archived,
			},
		];

		if (startDate) {
			filters.push({
				field: order,
				operator: ">=",
				value: startDate.value,
			});
		}

		if (endDate) {
			filters.push({
				field: order,
				operator: "<=",
				value: endDate.value,
			});
		}

		let collectionRef = projectFirestore.collection("Checklist");

		let companyChecklists;
		let thirdPartyChecklists;
		let sharedEmailChecklists;

		// First add either company, third party or shared by email filter to the querys
		sharedEmailChecklists = collectionRef.where(
			"sharedEmails",
			"array-contains",
			user.email
		);

		filters.forEach((filter) => {
			if (filter.value != undefined) {
				if (filter.field == "companyId") {
					companyChecklists = collectionRef.where(
						filter.field,
						filter.operator,
						filter.value
					);
				}
				if (filter.field == "thirdPartyCompanyId") {
					thirdPartyChecklists = collectionRef.where(
						filter.field,
						filter.operator,
						filter.value
					);
				}
			}
		});

		// add the rest of the filters to the querys
		let skipShared = false;

		filters.forEach((filter) => {
			if (filter.value != undefined) {
				if (
					filter.field == "thirdPartyCompanyId" ||
					filter.field == "companyId"
				) {
					// Do nothing
				} else if (
					filter.field == "tags" ||
					(filter.field == "users" && filter.value)
				) {
					// this means that it's an equipment or people tag and we should skip shared by email
					// becasue we can't have two array-contains querys and the people or equipment doesn't live
					// in the current users account anyway
					skipShared = true;
					companyChecklists = companyChecklists.where(
						filter.field,
						filter.operator,
						filter.value
					);
					thirdPartyChecklists = thirdPartyChecklists.where(
						filter.field,
						filter.operator,
						filter.value
					);
				} else {
					// Add the rest to all 3 collections refs
					sharedEmailChecklists = sharedEmailChecklists.where(
						filter.field,
						filter.operator,
						filter.value
					);
					companyChecklists = companyChecklists.where(
						filter.field,
						filter.operator,
						filter.value
					);
					thirdPartyChecklists = thirdPartyChecklists.where(
						filter.field,
						filter.operator,
						filter.value
					);
				}
			}
		});

		let data = await Promise.all([
			companyChecklists.orderBy(order, sort).get(),
			thirdPartyChecklists.orderBy(order, sort).get(),
			sharedEmailChecklists.orderBy(order, sort).get(),
		])
			.then(
				([
					companySnapshot,
					thirdPartySnapshot,
					sharedEmailSnapshot,
				]) => {
					let result = [];

					companySnapshot.docs.forEach((doc) => {
						result.push(doc);
					});

					if (status != "scheduled") {
						thirdPartySnapshot.docs.forEach((doc) => {
							result.push(doc);
						});

						if (!skipShared) {
							sharedEmailSnapshot.docs.forEach((doc) => {
								result.push(doc);
							});
						}
					}
					return result;
				}
			)
			.catch((err) => {
				console.error(err);
			});

		var checklistData = [];

		async function addChecklistToData(checklist) {
			checklistData.push({ ...checklist.data(), id: checklist.id });
		}

		console.log("Data: ", data);

		if (data) {
			data.map((checklist) => {
				// If it's the equipment or people page, show all docs
				if (equipmentId != null || equipmentId != undefined) {
					addChecklistToData(checklist);
				} else if (peopleId != null || peopleId != undefined) {
					addChecklistToData(checklist);
				} else {
					if (allChecklists) {
						if (role == "Admin") {
							// If admin and all checklists tab is selected, push all docs
							addChecklistToData(checklist);
						} else {
							// If not an admin, filter the results by the owner AND shared
							if (checklist.data().owner == id) {
								addChecklistToData(checklist);
							} else {
								let isSharedViaEmail = false;
								checklist
									.data()
									.sharedEmails.forEach((email) => {
										if (email == user.email) {
											// If it is shared via email, set to true
											isSharedViaEmail = true;
											addChecklistToData(checklist);
										}
									});

								// If it is shared via email, then no need to check sharedCompletedChecklistWithPeople
								if (!isSharedViaEmail) {
									var data =
										checklist.data()
											.sharedCompletedChecklistWithPeople;
									if (data != undefined) {
										for (const x of data) {
											if (x.id == id) {
												addChecklistToData(checklist);
											}
										}
									}
								}
							}
						}
					} else {
						// Either authored by you or shared
						if (authoredByYou) {
							if (checklist.data().owner == id) {
								addChecklistToData(checklist);
							}
						} else {
							var isSharedViaEmail = false;
							checklist.data().sharedEmails.forEach((email) => {
								if (email == user.email) {
									isSharedViaEmail = true;
									addChecklistToData(checklist);
								}
							});

							if (!isSharedViaEmail) {
								var data =
									checklist.data()
										.sharedCompletedChecklistWithPeople;
								if (data != undefined) {
									for (const x of data) {
										if (x.id == id) {
											addChecklistToData(checklist);
										}
									}
								}
							}
						}
					}
				}
			});
		}

		if (searchTags.value && searchTags.value.length) {
			checklistData = checklistData.filter((item) => {
				return searchTags.value
					.map((t) => t.toLowerCase())
					.every((t) =>
						item.tags.map((i) => i.toLowerCase()).includes(t)
					);
			});
		}

		// Sort the company and shared checklists
		const sortedChecklistData = checklistData.sort((b, a) => {
			if (status == "scheduled") {
				return b.scheduledDate.toMillis() - a.scheduledDate.toMillis();
			} else if (status == "complete") {
				return a.updatedAt.toMillis() - b.updatedAt.toMillis();
			} else {
				return a.createdAt.toMillis() - b.createdAt.toMillis();
			}
		});

		checklists.value = sortedChecklistData;

		return { checklists };
	};

	const archiveChecklistById = async (id) => {
		await projectFirestore.collection("Checklist").doc(id).update({
			isArchived: true,
			archivedDate: new Date(),
		});
	};

	const restoreChecklistById = async (id) => {
		await projectFirestore.collection("Checklist").doc(id).update({
			isArchived: false,
		});
	};

	return {
		syncChecklistSearchTags,
		getChecklist,
		checklist,
		error,
		checklists,
		getChecklists,
		archiveChecklistById,
		restoreChecklistById,
	};
};
export default checklistCollection;
