<template>
	<div>
		<!-- Errors -->
		<Alert v-if="fetchChecklistError" :message="fetchChecklistError" />

		<!-- Navigation loading -->
		<sub-nav-loading v-if="fetchingData || employeeDoc == null" />

		<!-- Navigation -->
		<div class="right-sub-nav fixed" v-if="employeeDoc">
			<div class="nav-row d-flex">
				<div class="sub-nav-left">
					<div class="checklists-count" v-if="checklistData">
						<b>
							{{ checklistData.length }} of {{ totalChecklists }}
						</b>
					</div>
					<div
						class="btn btn-icon"
						@click="renderMore"
						v-if="showMore"
					>
						<div class="text">Show More</div>
						<fa icon="plus" />
					</div>
					<div
						class="btn btn-icon"
						@click="refresh"
						v-if="!fetchingData"
					>
						<div class="text">Refresh</div>
						<fa icon="sync-alt" />
					</div>
					<div class="rm-form search">
						<div class="form-input">
							<vue-select
								v-model="searchTag"
								v-if="!fetchingData"
								:options="tags"
								searchable
								search-placeholder="Type to search and select tags..."
								placeholder="Filter list by tags..."
								:clear-on-select="true"
								:clear-on-close="true"
								:close-on-select="true"
								@blur="addSearchTag(searchTag)"
							></vue-select>
						</div>
					</div>
				</div>
				<div class="sub-nav-right">
					<div>
						<p v-if="status == 'active'">Started:</p>
						<p v-if="status == 'complete'">Completed:</p>
						<p v-if="status == 'scheduled'">Scheduled:</p>
					</div>
					<div v-if="selectedOption != 'custom'" class="date-select">
						<select v-model="selectedOption" class="custom-select">
							<option
								v-for="option in dateOptions"
								:key="option.value"
								:value="option.value"
							>
								{{ option.text }}
							</option>
						</select>
					</div>
					<div class="date-filter" v-if="selectedOption === 'custom'">
						<DatePicker
							:clearable="true"
							format="dd-MM-YYYY"
							type="date"
							class="datepicker"
							v-model="datePickerStartDate"
						/>
						<p>to</p>
						<DatePicker
							popover-align="center"
							:clearable="true"
							format="dd-MM-YYYY"
							type="date"
							class="datepicker"
							v-model="datePickerEndDate"
						/>
					</div>
					<div
						v-if="selectedOption === 'custom'"
						class="btn-icon"
						@click="resetSelection"
					>
						<fa icon="times" />
					</div>
				</div>
			</div>
			<div class="nav-row" v-if="searchTags && searchTags.length">
				<div class="sub-nav-left">
					<div class="pill-container fade-row">
						<div
							v-for="searchTag in searchTags"
							:key="searchTag"
							class="pill bg-blue"
						>
							<fa class="fa-tag" icon="tag" />
							{{ searchTag }}
							<fa
								class="fa-times"
								icon="times"
								@click="deleteSearchTag(searchTag)"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="list-container"
			v-bind:style="{
				'margin-top':
					searchTags && searchTags.length > 0 ? '50px' : '0px',
			}"
		>
			<div v-if="fetchingData || !employeeDoc">
				<div class="table-row-container">
					<div class="table-row-shimmer shine"></div>
					<div class="table-row-shimmer shine"></div>
					<div class="table-row-shimmer shine"></div>
				</div>
			</div>

			<div
				class="no-list vertical-center"
				v-else-if="
					!checklistData ||
					(checklistData && checklistData.length === 0)
				"
			>
				<div class="icon">
					<fa icon="clipboard" />
				</div>
				<p>No checklists found</p>
			</div>

			<!-- Items List -->
			<div class="table-row-container" v-else>
				<div
					v-for="checklist in checklistData"
					:key="checklist.checklistId"
				>
					<div
						class="table-row"
						:class="{
							overdue:
								checklist.status == 'scheduled' &&
								checklist.scheduledDate &&
								checklist.scheduledDate.toDate() < new Date(),
						}"
					>
						<div class="row-left">
							<div class="title no-img-title">
								{{ checklist.name }}
							</div>
							<div class="tags">
								<div
									v-for="tag in checklist.tags"
									:key="tag"
									v-if="checklist.tags"
								>
									<div
										:class="
											tagSearched(tag) === true
												? 'pill bg-blue'
												: 'pill'
										"
									>
										<div
											class="content"
											:class="
												tagSearched(tag) === false
													? 'hover'
													: ''
											"
											@click="addFromTags(tag)"
										>
											<fa class="fa-tag" icon="tag" />
											<p>{{ tag }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row-right">
							<div
								class="help-text"
								v-if="checklist.status == 'scheduled'"
							>
								<p>
									Scheduled for
									{{
										format(checklist.scheduledDate.toDate())
									}}
								</p>
							</div>
							<div
								class="help-text"
								v-if="checklist.status != 'scheduled'"
							>
								<p>{{ checklist.ownername }}</p>
							</div>
							<div
								class="help-text date"
								v-if="checklist.status == 'active'"
							>
								<p>
									{{ format(checklist.createdAt.toDate()) }}
								</p>
							</div>
							<div
								class="help-text date"
								v-if="checklist.status == 'complete'"
							>
								<p>
									{{ format(checklist.completedAt.toDate()) }}
								</p>
							</div>

							<div class="actions">
								<div
									v-if="
										companyId != checklist.companyId &&
										checklist.isArchived == true
									"
									class="actions-icon-btn disabled"
								>
									<fa icon="eye-slash" />
								</div>
								<div
									v-else
									class="actions-icon-btn"
									@click="
										viewChecklist(checklist.checklistId)
									"
								>
									<fa icon="eye" />
								</div>
								<div
									class="actions-icon-btn"
									@click="
										archiveChecklist(checklist.checklistId)
									"
									v-if="
										(employeeDoc.role == 'Admin' ||
											employeeDoc.manages.includes(
												checklist.location.id
											)) &&
										companyId == checklist.companyId &&
										checklist.isArchived == false
									"
								>
									<fa icon="archive" />
								</div>
								<div
									v-else-if="companyId != checklist.companyId"
									class="actions-icon-btn disabled"
								>
									<fa icon="lock" />
								</div>
								<div
									class="actions-icon-btn"
									@click="
										restoreChecklist(checklist.checklistId)
									"
									v-if="
										(employeeDoc.role == 'Admin' ||
											employeeDoc.manages.includes(
												checklist.location.id
											)) &&
										companyId == checklist.companyId &&
										checklist.isArchived == true
									"
								>
									<fa icon="undo" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="center-btn p-1rem"
					@click="renderMore"
					v-if="showMore"
				>
					<div class="btn-icon">
						<div class="text">Show More</div>
						<fa icon="angle-down" />
					</div>
				</div>
			</div>
		</div>

		<div class="alert" v-if="deleteAlert" @click="clearError">
			<span v-if="!errMessage">Are you sure you want to archive?</span>
			<span v-else>{{ errMessage }}</span>
			<button class="btn btn-success" @click="clearError">Cancel</button>
			<button class="btn btn-danger" @click="confirmArchive()">
				Archive
			</button>
		</div>
		<router-view />
	</div>
</template>

<script>
import PageLoading from "../PageLoading.vue";
import { useRoute, useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import { watchEffect } from "@vue/runtime-core";
import DatePicker from "../../../node_modules/vue3-datepicker";
import checklistCollection from "../../composables/checklistcollection";
import moment from "moment";
import SubNavLoading from "../../components/SubNavLoading.vue";
import ListLoading from "../ListLoading.vue";
import { useChecklistsStore } from "../../stores/checklistsStore";
import { useSessionStore } from "../../stores/sessionStore";
import { storeToRefs } from "pinia";
import { onMounted, nextTick } from "vue";
import Alert from "../../components/Alert.vue";

export default {
	components: { PageLoading, DatePicker, SubNavLoading, ListLoading, Alert },
	name: "ChecklistList",
	props: ["status", "companyId", "equipmentTag", "peopleId"],
	setup(props) {
		// Packages
		const router = useRouter();
		const route = useRoute();

		// Data stores
		const checklistsStore = useChecklistsStore();
		const {
			fetchingData,
			fetchChecklistError,
			scheduledChecklists,
			activeChecklists,
			completedChecklists,
			archivedChecklists,
			selectedOption,
		} = storeToRefs(checklistsStore);

		const sessionStore = useSessionStore();
		const { employeeDoc } = storeToRefs(sessionStore);

		// Functions
		const { archiveChecklistById, restoreChecklistById } =
			checklistCollection();

		// Company ID required for archiving
		const companyId = props.companyId;

		const tags = ref([]);
		const searchTag = ref();
		const searchTags = ref([]);
		let today = new Date();

		let dateOptions = [
			{ value: "lastMonth", text: "Last Month" },
			{ value: "lastWeek", text: "Last Week" },
			{ value: "yesterday", text: "Yesterday" },
			{ value: "today", text: "Today" },
			{ value: "tomorrow", text: "Tomorrow" },
			{ value: "thisWeek", text: "This Week" },
			{ value: "nextWeek", text: "Next Week" },
			{ value: "thisMonth", text: "This Month" },
			{ value: "nextMonth", text: "Next Month" },
			{ value: "custom", text: "Custom" },
		];

		const archived = ref(false);
		const status = ref(null);
		const startDate = ref();
		const datePickerStartDate = ref();
		const endDate = ref();
		const datePickerEndDate = ref();
		const peopleId = ref(null);
		const equipmentTag = ref(null);
		const checklists = ref([]);

		const fetchChecklists = async () => {
			checklists.value = [];
			await checklistsStore.fetchChecklists(
				startDate.value,
				endDate.value,
				status.value,
				archived.value
			);

			if (status.value == "scheduled") {
				checklists.value = scheduledChecklists.value;
			} else if (status.value == "active") {
				checklists.value = activeChecklists.value;
			} else if (status.value == "complete") {
				checklists.value = completedChecklists.value;
			} else {
				checklists.value = archivedChecklists.value;
			}
		};

		const lastSelected = ref(null);

		onMounted(async () => {
			checklists.value = [];
			equipmentTag.value = props.equipmentTag;
			peopleId.value = props.peopleId;

			if (route.path.endsWith("/scheduled")) {
				status.value = "scheduled";
			} else if (route.path.endsWith("/active")) {
				status.value = "active";
			} else if (route.path.endsWith("/completed")) {
				status.value = "complete";
			} else if (route.path.endsWith("/archived")) {
				archived.value = true;
			}

			// Set initial option
			if (selectedOption.value == null) {
				selectedOption.value = "thisWeek";
			}
		});

		watchEffect(() => {
			if (selectedOption.value) {
				if (selectedOption.value != "custom") {
					lastSelected.value = selectedOption.value;
				}
				switch (selectedOption.value) {
					case "lastMonth":
						startDate.value = moment(today)
							.subtract(1, "month")
							.startOf("month")
							.toDate();
						endDate.value = moment(today)
							.subtract(1, "month")
							.endOf("month")
							.toDate();
						break;
					case "lastWeek":
						startDate.value = moment(today)
							.subtract(1, "week")
							.startOf("isoWeek") // Changed to isoWeek for Monday start
							.toDate();
						endDate.value = moment(today)
							.subtract(1, "week")
							.endOf("isoWeek") // Changed to isoWeek for Monday start
							.toDate();
						break;
					case "yesterday":
						startDate.value = moment(today)
							.subtract(1, "day")
							.toDate();
						endDate.value = moment(today)
							.subtract(1, "day")
							.toDate();
						break;
					case "today":
						startDate.value = moment(today).toDate();
						endDate.value = moment(today).toDate();
						break;
					case "tomorrow":
						startDate.value = moment(today).add(1, "day").toDate();
						endDate.value = moment(today).add(1, "day").toDate();
						break;
					case "thisWeek":
						startDate.value = moment(today)
							.startOf("isoWeek") // Changed to isoWeek for Monday start
							.toDate();

						endDate.value = moment(today)
							.endOf("isoWeek") // Changed to isoWeek for Monday start
							.toDate();
						break;
					case "nextWeek":
						startDate.value = moment(today)
							.add(1, "week")
							.startOf("isoWeek") // Changed to isoWeek for Monday start
							.toDate();
						endDate.value = moment(today)
							.add(1, "week")
							.endOf("isoWeek") // Changed to isoWeek for Monday start
							.toDate();
						break;
					case "thisMonth":
						startDate.value = moment(today)
							.startOf("month")
							.toDate();
						endDate.value = moment(today).endOf("month").toDate();
						break;
					case "nextMonth":
						startDate.value = moment(today)
							.add(1, "month")
							.startOf("month")
							.toDate();
						endDate.value = moment(today)
							.add(1, "month")
							.endOf("month")
							.toDate();
						break;
					default:
						// When custom is selected, do not auto-set the dates
						startDate.value = null;
						endDate.value = null;
						break;
				}
				console.log(startDate.value, endDate.value);
			}
		});

		// Set dates when custom date is selected
		watchEffect(() => {
			if (datePickerStartDate.value && datePickerEndDate.value) {
				startDate.value = datePickerStartDate.value;
				endDate.value = datePickerEndDate.value;
			}
		});

		// Fetch data when dates are set or changed
		watchEffect(async () => {
			if (startDate.value && endDate.value) {
				await fetchChecklists();
			}
		});

		// To go back to previous selection when custom date is closed
		const resetSelection = () => {
			selectedOption.value = lastSelected.value;
		};

		// Clear cached data and fetch again
		const refresh = async () => {
			checklistsStore.refreshChecklists();

			// Fetch the checklists
			await fetchChecklists();
		};

		// Limit rendered items to stop the page from crashing
		const renderedItemsCount = ref(50);
		const showMore = ref(false);
		const renderedItems = ref(null);
		const totalChecklists = ref(0);

		const renderMore = () => {
			checklists.value.length > renderedItemsCount.value
				? (renderedItemsCount.value += 50)
				: (renderedItemsCount.value = checklists.value.length);

			if (renderedItemsCount.value >= checklists.value.length) {
				showMore.value = false;
			}
		};

		watchEffect(() => {
			if (
				checklists.value &&
				checklists.value.length > 0 &&
				employeeDoc.value
			) {
				renderedItems.value = null;

				let localChecklists = checklists.value;

				let dateCheckedList = [];

				if (startDate.value != null && endDate.value != null) {
					dateCheckedList = localChecklists.filter((doc) => {
						let dateField;
						if (status.value === "active") {
							dateField = doc.createdAt;
						} else if (status.value === "complete") {
							dateField = doc.completedAt;
						} else if (status.value === "scheduled") {
							dateField = doc.scheduledDate;
						} else {
							dateField = doc.createdAt;
						}

						let date = dateField.toDate();

						const isWithinDateRange =
							date >= startDate.value && date <= endDate.value;

						const matchesArchiveStatus =
							doc.isArchived === archived.value;

						return isWithinDateRange && matchesArchiveStatus;
					});
				}

				totalChecklists.value = dateCheckedList.length;

				if (searchTags.value && searchTags.value.length > 0) {
					const lowerSearchTags = searchTags.value.map((t) =>
						t.toLowerCase()
					);

					dateCheckedList = dateCheckedList.filter((item) => {
						const lowerItemTags = new Set(
							item.tags.map((i) => i.toLowerCase())
						);
						return lowerSearchTags.every((t) =>
							lowerItemTags.has(t)
						);
					});
				}

				let checklistsToRender = dateCheckedList.slice(
					0,
					renderedItemsCount.value
				);

				renderedItems.value = checklistsToRender;

				showMore.value =
					renderedItems.value.length < totalChecklists.value;
			}
		});

		// Listen to renderedItems to filter based on tags, equipment and people
		const checklistData = ref([]);

		watchEffect(() => {
			if (renderedItems.value) {
				let localRenderedItems = renderedItems.value;

				if (equipmentTag.value != null) {
					localRenderedItems = localRenderedItems.filter((doc) =>
						doc.equipments?.includes(props.equipmentTag)
					);
				}

				if (peopleId.value != null) {
					localRenderedItems = localRenderedItems.filter((doc) =>
						doc.users?.includes(props.peopleId)
					);
				}

				let localTags = new Set();

				localRenderedItems.forEach((doc) => {
					doc.tags.forEach((tag) => {
						if (tag != "") {
							localTags.add(tag);
						}
					});
				});

				tags.value = Array.from(localTags);

				checklistData.value = localRenderedItems;
			}
		});

		// Navigate to the checklist detail page on click
		const viewChecklist = (id) => {
			let routeData = router.resolve({
				name: "ChecklistDetail",
				params: { id: id },
			});
			window.open(routeData.href, "_blank");
		};

		// click to clear error
		const errMessage = ref(null);
		const deleteAlert = ref(false);

		const clearError = () => {
			errMessage.value = null;
			deleteAlert.value = false;
		};

		// Add tag to search from checklist tags
		const addFromTags = (t) => {
			searchTag.value = t;
			addSearchTag();
		};

		// Add tag to search
		const addSearchTag = () => {
			let tag;
			if (searchTag.value != null && searchTag.value != "") {
				tag = searchTag.value.trim();
			} else {
				return;
			}

			if (!searchTags.value.includes(tag)) {
				searchTags.value.push(tag);
			}
			// searchTag.value = "";
			// if (searchTags.value.length) {
			// }

			searchTag.value = null;
		};

		// Delete tag from search
		const deleteSearchTag = (searchTag) => {
			searchTags.value = searchTags.value.filter((item) => {
				return searchTag !== item;
			});
		};

		// Check if tag is already searched
		// For pill styling
		const tagSearched = (value) => {
			if (searchTags.value && searchTags.value.length > 0) {
				var index = searchTags.value.findIndex(
					(item) => item.toLowerCase() == value.toLowerCase()
				);
				if (index == -1) {
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		};

		// Format the dates to display
		const format = (value) => {
			return moment(value).format("DD/MM/YYYY");
		};

		// Archive and restore a checklist
		let checklistIdToArchive = "";

		const archiveChecklist = async (id) => {
			checklistIdToArchive = id;
			deleteAlert.value = true;
		};

		const confirmArchive = async () => {
			await archiveChecklistById(checklistIdToArchive);

			checklistData.value = checklistData.value.filter(
				(checklist) => checklist.checklistId !== checklistIdToArchive
			);
			checklistIdToArchive = "";

			await refresh();
		};

		const restoreChecklist = async (id) => {
			await restoreChecklistById(id);

			checklistData.value = checklistData.value.filter(
				(checklist) => checklist.checklistId !== id
			);

			await refresh();
		};

		return {
			checklistData,
			fetchingData,
			fetchChecklistError,
			deleteAlert,
			searchTag,
			searchTags,
			errMessage,
			companyId,
			tags,
			employeeDoc,
			selectedOption,
			dateOptions,
			showMore,
			datePickerStartDate,
			datePickerEndDate,
			status,
			viewChecklist,
			archiveChecklist,
			restoreChecklist,
			confirmArchive,
			addSearchTag,
			deleteSearchTag,
			clearError,
			format,
			tagSearched,
			addFromTags,
			refresh,
			resetSelection,
			renderMore,
			totalChecklists,
		};
	},
};
</script>

<style lang="scss" scoped>
.pointer {
	cursor: pointer;

	&:hover {
		color: $rm-pink;
	}
}
.pill {
	&:hover {
		background-color: $rm-blue;
		color: white;
	}
}

.sub-nav-right {
	margin-right: 1.5em;
	.archived-btn {
		cursor: pointer;
	}
	.date-select {
		.custom-select {
			padding: 8px 16px;
			font-family: "Poppins", sans-serif;
			color: $rm-blue;
			font-size: 1em;
			font-weight: bold;
			border: none;
			background-color: transparent;
			cursor: pointer;
			&:hover {
				color: $rm-pink;
			}
			&:focus {
				outline: none;
			}
		}
	}
	.date-filter {
		display: flex;
		gap: 0.5em;
		.select-date {
			text-decoration: underline;
			color: $rm-blue;
		}
	}
}

.tags {
	display: flex;
	margin-right: 0.5em;
}

.disabled {
	&:hover {
		cursor: not-allowed !important;
		color: $rm-light-grey !important;
	}
}

.checklists-count {
	white-space: nowrap;
}

.overdue {
	border-left: 8px solid $rm-danger;
}

.table-row {
	.row-left {
		.title {
			//	min-width: 220px;
			font-size: 12px;
			width: 100px;
			padding: 0 2em 0 0;

			@include respond(small) {
				font-size: 14px;
				width: 230px;
			}
			@include respond(medium) {
				width: 250px;
			}
			@include respond(large) {
				font-size: 1em;
				width: 300px;
			}
		}
	}
	.row-right {
		.date {
			width: 11ch;
		}
	}
}

.search {
	padding-top: 1em;
}
</style>
